import { EmailRounded, LocalDiningRounded } from '@mui/icons-material'
import Person from '@mui/icons-material/Person'
import { Box } from '@mui/joy'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import Tab, { tabClasses } from '@mui/joy/Tab'
import TabList from '@mui/joy/TabList'
import Tabs from '@mui/joy/Tabs'
import { useMatch, useNavigate } from 'react-router'

export default function BottomNavigator() {
    const navigate = useNavigate()
    const currentUrl = useMatch('/:currentPage')?.params.currentPage

    console.log('currentUrl', currentUrl)

    return (
        <Box
            sx={{
                display: {
                    xs: 'flex',
                    md: 'none',
                },
                position: 'absolute',
                bottom: 0,
                width: '100%',
            }}
        >
            <Tabs
                size="lg"
                aria-label="Bottom Navigation"
                value={currentUrl}
                onChange={(event, value) => navigate('/' + value)}
                sx={theme => ({
                    p: 1,
                    borderRadius: 16,
                    maxWidth: 400,
                    mx: 'auto',
                    [`& .${tabClasses.root}`]: {
                        py: 1,
                        flex: 1,
                        transition: '0.3s',
                        fontWeight: 'md',
                        fontSize: 'md',
                        [`&:not(.${tabClasses.selected}):not(:hover)`]: {
                            opacity: 0.7,
                        },
                    },
                })}
            >
                <TabList
                    variant="plain"
                    size="sm"
                    disableUnderline
                    sx={{ borderRadius: 'lg', p: 0 }}
                >
                    <Tab
                        disableIndicator
                        orientation="vertical"
                        value="restaurants"
                        //{...(index === 0 && { color: 'primary' })}
                    >
                        <ListItemDecorator>
                            <LocalDiningRounded />
                        </ListItemDecorator>
                        Nearby
                    </Tab>
                    {/*<Tab
                        disableIndicator
                        orientation="vertical"
                        value="restaurants"
                        //{...(index === 1 && { color: 'primary' })}
                    >
                        <ListItemDecorator>
                            <LunchDiningRounded />
                        </ListItemDecorator>
                        Restaurants
                    </Tab>*/}
                    <Tab
                        disableIndicator
                        orientation="vertical"
                        value="suggest"
                        //{...(index === 2 && { color: 'primary' })}
                    >
                        <ListItemDecorator>
                            <EmailRounded />
                        </ListItemDecorator>
                        Suggest
                    </Tab>
                    <Tab
                        disableIndicator
                        orientation="vertical"
                        value="profile"
                        //{...(index === 3 && { color: 'primary' })}
                    >
                        <ListItemDecorator>
                            <Person />
                        </ListItemDecorator>
                        Profile
                    </Tab>
                </TabList>
            </Tabs>
        </Box>
    )
}
