import z from 'zod'

export const AddUserSchema = z.object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().email(),
    mobileNumber: z.string().optional(),
    avatarImage: z.string().optional(),
})

export type AddUserSchema = z.infer<typeof AddUserSchema>

export interface InternalUserSchema extends AddUserSchema {
    id: string
}
