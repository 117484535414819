import { formatAddress } from '@halah/core/src/schemas/generic'
import { InternalRestaurantSchema } from '@halah/core/src/schemas/restaurant'
import { ApprovalRounded, CloseRounded } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/joy'
import { useApi } from 'api'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'

const Suggestion = ({ restaurant, remove }: { restaurant: InternalRestaurantSchema; remove }) => {
    const api = useApi()

    const [loading, setLoading] = useState(false)

    return (
        <Stack
            key={restaurant.id}
            direction="row"
            gap={4}
        >
            <Box
                sx={{
                    my: 2,
                }}
            >
                <Typography>{restaurant.restaurantName}</Typography>
                <Typography>{formatAddress(restaurant.location)}</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <IconButton
                    loading={loading}
                    onClick={async () => {
                        setLoading(true)
                        await api.post(`/restaurants/${restaurant.id}/verify`)
                        remove()
                        setLoading(false)
                    }}
                    color="success"
                >
                    <ApprovalRounded />
                </IconButton>
                <IconButton
                    loading={loading}
                    onClick={async () => {
                        console.log('Reject', restaurant)
                        setLoading(true)
                        await api.post(`/restaurants/${restaurant.id}/reject`)
                        remove()
                        setLoading(false)
                    }}
                    color="danger"
                >
                    <CloseRounded />
                </IconButton>
            </Box>
        </Stack>
    )
}

export default () => {
    const api = useApi()
    const [restaurants, setRestaurants] = useState<InternalRestaurantSchema[]>()

    useEffect(() => {
        const fetchRestaurants = async () => {
            const { data } = await api.post('/restaurants/search', {
                verified: false,
            })
            setRestaurants(data.hits.map(prop('document')))
        }
        fetchRestaurants()
    }, [])

    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    sm: 4,
                },
            }}
        >
            <Typography
                level="h2"
                sx={{ mb: 4 }}
            >
                Awaiting Approval
            </Typography>
            {restaurants?.map(restaurant => (
                <Suggestion
                    key={restaurant.id}
                    restaurant={restaurant}
                    remove={() => {
                        setRestaurants(restaurants.filter(r => r.id !== restaurant.id))
                    }}
                />
            ))}
        </Box>
    )
}
