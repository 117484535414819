import { extendTheme } from '@mui/joy/styles'
import { forwardRef } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

const LinkBehavior = forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
    const { href, ...other } = props
    // Map href (Material UI) -> to (react-router)
    return (
        <RouterLink
            ref={ref}
            to={href}
            {...other}
        />
    )
})

export default extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: '#E6F5EB', // A very light shade of green
                    100: '#CCEAD6', // A light shade of green
                    200: '#99D5AD', // A lighter shade
                    300: '#66BF85', // A medium light shade
                    400: '#33AA5C', // Slightly lighter than the base
                    500: '#00A650', // The base color
                    600: '#009945', // A slightly darker shade
                    700: '#008D3B', // A darker shade
                    800: '#007A32', // A very dark shade
                    900: '#006429', // The darkest shade
                },
            },
        },
        dark: {
            palette: {
                primary: {
                    50: '#D9ECD6', // A very light shade of green
                    100: '#B3D9AD', // A light shade of green
                    200: '#80C57F', // A lighter shade
                    300: '#66B36A', // A medium light shade
                    400: '#4DA856', // Slightly lighter than the base
                    500: '#00A650', // The base color
                    600: '#008C44', // A darker shade
                    700: '#007338', // A darker shade
                    800: '#005A2C', // A very dark shade
                    900: '#003A1D', // The darkest shade
                },
            },
        },
    },
    components: {
        JoyTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: 'inherit',
                },
            },
        },
        JoyLink: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        JoyListItem: {
            defaultProps: {
                //component: LinkBehavior,
            },
        },
        /*JoyStack: {
            defaultProps: ({ theme }) => ({ spacing: theme.spacing(2) }),

            },
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    gap: theme.breakpoints.up('sm') ? 2 : 1,
                    direction: 'column',
                    [theme.breakpoints.up('sm')]: {
                        direction: 'row',
                    },
                    mt: 9,
                }),
            }
        },*/
    },
})
