import z from 'zod'
import { AddressSchema } from './generic'

export const HalalStatuses = ['Certified', 'Muslim Owned', 'Halal Ingredients', 'Serves Alcohol'] as const
export type HalalStatus = (typeof HalalStatuses)[number]

export const RestaurantSchema = z.object({
    restaurantName: z.string(),
    placeId: z.string(),
    location: AddressSchema,
    geo: z.array(z.number()),
    //description: z.string(),
    //image: z.string(),
    rating: z.number().optional(),
    ratingCount: z.number().optional(),
    //categoryIds: z.array(z.string()),
    halalStatus: z.array(z.enum(HalalStatuses)),
    cuisines: z.array(
        z.object({
            id: z.string(),
        }),
    ),
})

export type RestaurantSchema = z.infer<typeof RestaurantSchema>

export type InternalRestaurantSchema = RestaurantSchema & {
    id: string
    verified: 'true' | 'false'
}

export type RestaurantTypesenseSchema = InternalRestaurantSchema & {
    distance: number
}
