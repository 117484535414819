import { AddressSchema, formatAddress } from '@halah/core/src/schemas/generic'
import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { useEffect, useMemo, useState } from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { AutocompleteProps, SlotAutocomplete } from './autocomplete'

interface LocationAutocompleteProps
    extends Omit<
        AutocompleteProps<{ description: string; placeId?: string }, false, false, false>,
        'error' | 'options' | 'value' | 'onChange'
    > {
    field
    formState
    value: AddressSchema
    onChange: (value: AddressSchema, geo: [number, number], placeId: string) => void
}

const LocationAutocomplete = (props: LocationAutocompleteProps) => {
    const geocodingLib = useMapsLibrary('geocoding')
    const geocoder = useMemo(() => geocodingLib && new geocodingLib.Geocoder(), [geocodingLib])

    //const bias = new google.maps.LatLng(-28.0484317, 153.4214467)
    //console.log('bias', bias)

    const {
        ready,
        value,
        suggestions: { status, data, loading },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: 'au' },
            types: ['restaurant'],
            //locationBias: bias,
        },
        debounce: 300,
    })

    const [internalVal, setInternalVal] = useState(props.value ? formatAddress(props.value, 'full') : null)
    useEffect(() => {
        if (internalVal) {
            setValue(internalVal)
        } else {
            clearSuggestions()
        }
    }, [internalVal])
    useEffect(() => {
        setInternalVal(props.value ? formatAddress(props.value, 'full') : null)
    }, [props.value])

    return (
        <SlotAutocomplete
            {...props}
            value={internalVal ? { description: internalVal } : null}
            options={data.map(({ description, place_id }) => ({
                description,
                placeId: place_id,
            }))}
            onInputChange={(e: any) => {
                if (e) {
                    setInternalVal(e.target.value)
                }
            }}
            onBlur={() => setInternalVal(props.value ? formatAddress(props.value, 'full') : null)}
            getOptionLabel={(option: any) => option.description}
            isOptionEqualToValue={(option: any, value: any) =>
                option.description.replace(', Australia', '') === value.description
            }
            onChange={async (e, v) => {
                console.log('v', v)
                const placeId = v?.placeId ?? null

                if (placeId) {
                    console.log('geocoder', geocoder)
                    const geo = await geocoder?.geocode({ placeId }).then(({ results }) => results[0])

                    const getGeoProp = (prop: string) =>
                        geo?.address_components.find(c => c.types.includes(prop))?.short_name ?? undefined

                    console.log('geo', geo)
                    if (geo) {
                        const newAddress: AddressSchema = {
                            unitNumber: getGeoProp('subpremise')!,
                            streetNumber: getGeoProp('street_number')!,
                            streetName: getGeoProp('route')!,
                            suburb: getGeoProp('locality')!,
                            state: getGeoProp('administrative_area_level_1') as any,
                            postcode: getGeoProp('postal_code')!,
                        }
                        console.log('newAddress', newAddress)
                        props.onChange(
                            newAddress,
                            [geo.geometry.location.lat(), geo.geometry.location.lng()],
                            placeId,
                        )
                    }
                }
            }}
            loading={loading}
        />
    )
}

export default LocationAutocomplete
