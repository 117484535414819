import { useTheme } from '@mui/joy'
import Box from '@mui/joy/Box'
import { AuthWrapper } from 'auth'
import AwaitingApproval from 'awaitingApproval'
import BottomNavigator from 'components/bottomNavigator'
import Header from 'components/header'
import Sidebar from 'components/sidebar'
import Login from 'newLogin'
import PrayerMap from 'prayerMap'
import { useEffect } from 'react'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import RestaurantMap from 'restaurantMap'
import SignUp from 'signUp'
import AddRestaurant from './addRestaurant'

export const WithProviders = ({ providers, children }) => {
    const Provider = providers[0]

    return (
        <Provider>
            {providers.length > 1 ? (
                <WithProviders providers={providers.slice(1, providers.length)}>{children}</WithProviders>
            ) : (
                children
            )}
        </Provider>
    )
}

const LoggedInRoutes = () => {
    const theme = useTheme()

    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            <Header />
            <Sidebar />
            <Box
                component="main"
                className="MainContent"
                sx={{
                    pt: {
                        xs: 'calc(12px + var(--Header-height))',
                        md: 3,
                    },
                    pb: {
                        xs: 4,
                        sm: 4,
                        md: 6,
                    },
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 0,
                    height: '100dvh',
                    gap: 1,
                    overflow: 'auto',
                    [theme.breakpoints.down('md')]: {
                        pb: '106px',
                    },
                    position: 'relative',
                }}
            >
                <Routes>
                    <Route
                        path="/suggest"
                        element={<AddRestaurant />}
                    />
                    <Route
                        path="/restaurants"
                        element={<RestaurantMap />}
                    />
                    <Route
                        path="/awaitingapproval"
                        element={<AwaitingApproval />}
                    />
                    <Route
                        path="/prayerspaces"
                        element={<PrayerMap />}
                    />
                    <Route
                        index
                        element={
                            <Navigate
                                to="/restaurants"
                                replace
                            />
                        }
                    />
                </Routes>
            </Box>

            <BottomNavigator />
        </Box>
    )
}

const WithAuthWrapper = () => {
    return (
        <AuthWrapper>
            <Routes>
                <Route
                    path="auth/signup"
                    element={<SignUp />}
                />
                <Route
                    path="auth/*"
                    element={<Login />}
                />
                <Route
                    path="/*"
                    element={<LoggedInRoutes />}
                />
            </Routes>
        </AuthWrapper>
    )
}

export default () => {
    const [searchParams] = useSearchParams()
    const expoPushToken = searchParams.get('expoPushToken')

    useEffect(() => {
        if (expoPushToken) {
            localStorage.setItem('expoPushToken', expoPushToken)
        }
    }, [expoPushToken])

    return (
        <Routes>
            <Route
                path="/*"
                element={<WithAuthWrapper />}
            />
        </Routes>
    )
}
