import { HalalStatus, HalalStatuses, RestaurantSchema } from '@halah/core/src/schemas/restaurant'
import { zodResolver } from '@hookform/resolvers/zod'
import { Autocomplete, Box, Button, Checkbox, List, ListItem, Stack, Typography } from '@mui/joy'
import { useApi } from 'api'
import { SlotInput, SlotWrapper } from 'components/input'
import LocationAutocomplete from 'components/LocationAutocomplete'
import { pick } from 'ramda'
import { forwardRef, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useDebounce } from 'use-debounce'

interface MultipleProps {
    value?: { id: string }[]
    onChange?: (values: { id: string }[]) => void
    //filter?: (value: string) => boolean
}

export const CuisineInput = forwardRef(({ value, onChange, ...props }: MultipleProps, ref: any) => {
    const api = useApi()
    const [search, setSearch] = useState('')
    const [debouncedSearch] = useDebounce(search, 200)

    const [found, setFound] = useState<{ id: string; name: string }[]>([])

    /*useEffect(() => {
        api.post('/cuisine/search', {
            search: debouncedSearch,
        }).then(({ data }) => {
            setFound(data)
        })
    }, [debouncedSearch])*/

    useEffect(() => {
        api.post('/cuisines/search', {}).then(({ data }) => {
            setFound(data.hits.map((x: any) => x.document))
        })
    }, [])

    return (
        <Autocomplete
            //ref={ref}
            //onInputChange={(e, value) => setSearch(value)}
            multiple
            options={found.map(pick(['id']))}
            value={value}
            getOptionLabel={option => found.find(x => x.id === option.id)?.name ?? ''}
            onChange={(_, values) => {
                onChange?.(values)
            }}
            {...props}
        />
    )
})

export default () => {
    const api = useApi()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const { handleSubmit, control, setValue, formState, getValues, reset } = useForm<RestaurantSchema>({
        resolver: zodResolver(RestaurantSchema),
        defaultValues: {
            halalStatus: [],
            cuisines: [],
        },
    })

    const {
        fields: conjunctionalAgreements,
        append: appendAgreement,
        remove: removeAgreement,
    } = useFieldArray({
        control,
        name: 'cuisines',
    })

    const onSubmit = async data => {
        setLoading(true)
        await api.post('/restaurants/add', data)
        reset()
        setLoading(false)
    }

    if (Object.keys(formState.errors).length) {
        console.log('errors', formState.errors)
    }

    console.log('formState', getValues())

    return (
        <Box sx={{ p: 4, pt: 4 }}>
            <Stack
                direction="row"
                sx={{
                    gap: 2,
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Typography level="h2">Suggest Restaurant</Typography>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack gap={2}>
                    <Controller
                        name="restaurantName"
                        control={control}
                        render={field => (
                            <SlotInput
                                label="Restaurant Name"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="location"
                        control={control}
                        render={field => (
                            <LocationAutocomplete
                                label="Address"
                                {...field}
                                value={(field.field.value as any) ?? null}
                                onChange={(loc, geo, placeId) => {
                                    field.field.onChange(loc)
                                    setValue('geo', geo)
                                    setValue('placeId', placeId)
                                }}
                                slotProps={{
                                    input: {
                                        tabIndex: 0,
                                    },
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="halalStatus"
                        control={control}
                        render={({ field }) => (
                            <Box>
                                <Typography
                                    id="sandwich-group"
                                    level="body-sm"
                                    fontWeight="lg"
                                    mb={1}
                                >
                                    Halal Status
                                </Typography>
                                <Box
                                    role="group"
                                    aria-labelledby="sandwich-group"
                                >
                                    <List size="sm">
                                        {HalalStatuses.map((status: HalalStatus) => (
                                            <ListItem key={status}>
                                                <Checkbox
                                                    label={status}
                                                    checked={field.value.includes(status)}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            field.onChange([...field.value, status])
                                                        } else {
                                                            field.onChange(
                                                                field.value.filter(x => x !== status),
                                                            )
                                                        }
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Box>
                        )}
                    />
                    <Controller
                        name="cuisines"
                        control={control}
                        render={field => (
                            <SlotWrapper
                                {...field}
                                label="Cuisines"
                            >
                                <CuisineInput
                                    onChange={value => {
                                        console.log('change', value)
                                        field.field.onChange(value)
                                    }}
                                />
                            </SlotWrapper>
                        )}
                    />
                </Stack>
                <Button
                    sx={{ mt: 6 }}
                    type="submit"
                    loading={loading}
                >
                    Send Suggestion
                </Button>
            </form>
        </Box>
    )
}
