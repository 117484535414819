import { RestaurantTypesenseSchema } from '@halah/core/src/schemas/restaurant'
import { Star } from '@mui/icons-material'
import { AspectRatio, Box, Button, Card, CardContent, CardOverflow, Chip, Typography } from '@mui/joy'
import axios from 'axios'
import { useMyLocation } from 'myLocation'
import { useEffect, useState } from 'react'

const RenderRestaurant = ({ restaurant }: { restaurant: RestaurantTypesenseSchema }) => {
    const { position } = useMyLocation()
    const [googleInfo, setGoogleInfo] = useState<any>()

    useEffect(() => {
        const params = new URLSearchParams({
            fields: 'photos,rating,userRatingCount,displayName',
            key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
        })

        axios
            .get(`https://places.googleapis.com/v1/places/${restaurant.placeId}?${params.toString()}`)
            .then(({ data }) => {
                setGoogleInfo(data)
            })
    }, [restaurant.id])

    console.log('googleInfo', googleInfo)

    const photoParams = new URLSearchParams({
        key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
        maxHeightPx: '200',
    })

    return (
        <Card
            sx={{
                width: 400,
                maxWidth: '100%',
                boxShadow: 'lg',
                my: 2,
                position: 'relative',
            }}
        >
            <CardOverflow>
                <AspectRatio sx={{ minWidth: 200 }}>
                    <img
                        src={
                            (googleInfo?.photos?.length ?? 0) > 0
                                ? `https://places.googleapis.com/v1/${googleInfo!.photos![0].name}/media?${photoParams.toString()}`
                                : undefined
                        }
                        loading="lazy"
                        alt=""
                    />
                </AspectRatio>
            </CardOverflow>
            <CardContent>
                <Chip
                    sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                    }}
                >
                    {(restaurant.distance / 1000).toLocaleString('en-AU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}{' '}
                    km
                </Chip>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>{restaurant.restaurantName}</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                        }}
                    >
                        <Star />
                        <Typography>
                            {googleInfo?.rating} ({googleInfo?.userRatingCount} reviews)
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
            <CardOverflow>
                <Button
                    variant="solid"
                    color="danger"
                    size="lg"
                    onClick={() => {
                        const params = new URLSearchParams({
                            api: '1',
                            origin: 'Current Location',
                            destination: googleInfo?.displayName.text,
                            destination_place_id: restaurant.placeId,
                            destinationPlaceId: restaurant.placeId,
                        })
                        //console.log(`https://www.google.com/maps/dir/?${params.toString()}`)
                        window.location.href = `https://www.google.com/maps/dir/?${params.toString()}`
                    }}
                >
                    Get Directions
                </Button>
            </CardOverflow>
        </Card>
    )
}

export default function ListView({ restaurants }: { restaurants: RestaurantTypesenseSchema[] }) {
    return (
        <Box
            sx={{
                my: 2,
                height: '100%',
                overflowY: 'auto',
            }}
        >
            {restaurants.map(restaurant => (
                <RenderRestaurant restaurant={restaurant} />
            ))}
        </Box>
    )
}

//https://maps.googleapis.com/maps/api/mapsjs/mapConfigs:batchGet?language=en-AU&region=US&alt=protojson&major_version=58&minor_version=5a&map_ids=1&map_type=1
//https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJv5-qYKhXkWsRQkqapetJlps&key=AIzaSyB0o8duno8yrE-ggnRuflLpxsXJ46egbjY
