import CssBaseline from '@mui/joy/CssBaseline'
import { CssVarsProvider } from '@mui/joy/styles'
import { APIProvider } from '@vis.gl/react-google-maps'
import AppRoutes from 'appRoutes'
import { SnackbarProvider } from 'components/snackbar'
import { MyLocationProvider } from 'myLocation'
import theme from './theme'

const { VITE_GOOGLE_MAPS_API_KEY } = import.meta.env

function App() {
    return (
        <APIProvider
            apiKey={VITE_GOOGLE_MAPS_API_KEY}
            libraries={['places', 'geocoding']}
        >
            <MyLocationProvider>
                <CssVarsProvider
                    defaultMode="system"
                    disableTransitionOnChange
                    theme={theme}
                >
                    <CssBaseline />
                    <SnackbarProvider>
                        <AppRoutes />
                    </SnackbarProvider>
                </CssVarsProvider>
            </MyLocationProvider>
        </APIProvider>
    )
}

export default App
