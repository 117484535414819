import { AddUserSchema } from '@halah/core/src/schemas/user'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Stack, Typography } from '@mui/joy'
import axios from 'axios'
import { SlotInput } from 'components/input'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import logo from './images/logo.svg'

export default () => {
    const navigate = useNavigate()

    const { handleSubmit, control, register, formState } = useForm<AddUserSchema>({
        resolver: zodResolver(AddUserSchema),
    })

    const [error, setError] = useState<string>()

    const onSubmit = async data => {
        try {
            await axios.post(`${import.meta.env.VITE_APP_API_URL}/signup`, data)
            navigate('/auth/login')
        } catch (error: any) {
            console.error(error)
            setError(error.response?.data?.message || 'An error occurred')
        }
    }

    return (
        <Box
            sx={{
                py: 8,
                px: 12,
                maxWidth: '550px',
                mx: 'auto',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    mb: 6,
                }}
            >
                <img
                    src={logo}
                    style={{ width: '200px' }}
                />
                <Typography fontSize={22}>Get your halal on</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <Controller
                        name="firstName"
                        control={control}
                        render={field => (
                            <SlotInput
                                {...field}
                                label="First Name"
                            />
                        )}
                    />
                    <Controller
                        name="lastName"
                        control={control}
                        render={field => (
                            <SlotInput
                                {...field}
                                label="Last Name"
                            />
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        render={field => (
                            <SlotInput
                                {...field}
                                label="Email"
                            />
                        )}
                    />
                    <Controller
                        name="mobileNumber"
                        control={control}
                        render={field => (
                            <SlotInput
                                {...field}
                                label="Mobile Number"
                            />
                        )}
                    />
                    <Box />
                    <Button
                        sx={{ mt: 6 }}
                        type="submit"
                        loading={formState.isSubmitting}
                    >
                        Sign Up
                    </Button>
                    {error && <Typography color="danger">{error}</Typography>}
                </Stack>
            </form>
        </Box>
    )
}
