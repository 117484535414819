import { InternalRestaurantSchema, RestaurantTypesenseSchema } from '@halah/core/src/schemas/restaurant'
import { FilterAltRounded, FormatListBulletedRounded, MapRounded } from '@mui/icons-material'
import { Badge, Box, IconButton, useTheme } from '@mui/joy'
import { AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps'
import { useApi } from 'api'
import { useSearchParamState } from 'helperFunctions/react'
import { useMyLocation } from 'myLocation'
import { useEffect, useState } from 'react'
import Filter from './filter'
import ListView from './listView'

export const CoreMapView = ({ position }: { position: GeolocationPosition }) => {
    const api = useApi()
    const theme = useTheme()

    const [restaurants, setRestaurants] = useState<RestaurantTypesenseSchema[]>()
    const [filters, setFilters] = useState<any>({})
    const [screen, setScreen] = useSearchParamState<'map' | 'list' | 'filter'>('view', 'map')

    console.log('restaurants', restaurants)

    useEffect(() => {
        const fetchRestaurants = async () => {
            const { data } = await api.post('/restaurants/search', {
                geo: `${position.coords.latitude}, ${position.coords.longitude}`,
                ...filters,
            })
            setRestaurants(
                data.hits.map(x => ({
                    ...x.document,
                    distance: x.geo_distance_meters.geo,
                })),
            )
        }
        fetchRestaurants()
    }, [filters])

    return (
        <>
            <IconButton
                sx={{
                    position: 'absolute',
                    top: 12,
                    left: 24,
                    zIndex: 9999,
                }}
                onClick={() => setScreen(screen == 'map' ? 'list' : 'map')}
            >
                {screen === 'map' ? <FormatListBulletedRounded /> : <MapRounded />}
            </IconButton>
            <Badge
                sx={{
                    position: 'absolute',
                    top: 12,
                    right: 24,
                    zIndex: 9999,
                }}
                badgeContent={(filters.halalStatus?.length ?? 0) + (filters.cuisines?.length ?? 0)}
            >
                <IconButton onClick={() => setScreen('filter')}>
                    <FilterAltRounded />
                </IconButton>
            </Badge>
            {screen == 'filter' && (
                <Filter
                    filters={filters}
                    setFilters={setFilters}
                    setScreen={setScreen}
                />
            )}

            <Box
                sx={{
                    display: screen === 'map' ? 'block' : 'none',
                    width: '100%',
                    height: '100%',
                    borderRadius: 'sm',
                    overflow: 'hidden',
                    position: 'relative',
                    [theme.breakpoints.up('md')]: {
                        mt: 5,
                    },
                }}
            >
                <Map
                    mapId={'1'}
                    //defaultCenter={{ lat: -27.467331464, lng: 153.02333324 }}
                    defaultCenter={{
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }}
                    defaultZoom={11}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                >
                    {restaurants?.map((r: InternalRestaurantSchema) => (
                        <AdvancedMarker
                            key={r.id}
                            //position={{lat: 10, lng: 10}}
                            position={{
                                lat: r.geo[0],
                                lng: r.geo[1],
                            }}
                            clickable={true}
                            //onClick={() => navigate(`/${orgId}/listings/${type}/${listing.listingId}`)}
                            title={r.restaurantName}
                        >
                            <Pin
                            /*background={theme.palette[listingStatusToColor(listing.listingStatus)][400]}
                                borderColor={theme.palette[listingStatusToColor(listing.listingStatus)][400]}
                                glphColor={theme.palette[listingStatusToColor(listing.listingStatus)][500]}*/
                            />
                        </AdvancedMarker>
                    ))}
                </Map>
            </Box>

            <Box
                sx={{
                    display: screen === 'list' ? 'block' : 'none',
                    width: '100%',
                    height: '100%',
                    borderRadius: 'sm',
                    overflow: 'hidden',
                    position: 'relative',
                    [theme.breakpoints.up('md')]: {
                        mt: 5,
                    },
                }}
            >
                <ListView restaurants={restaurants ?? []} />
            </Box>
        </>
    )
}

export default () => {
    const { position, error } = useMyLocation()

    if (error) {
        return <Box sx={{ p: 6 }}>{error}</Box>
    }

    if (!position) {
        return <Box sx={{ p: 6 }}>Loading...</Box>
    }

    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    sm: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                },
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    flex: '1 1 auto',
                }}
            >
                <CoreMapView position={position} />
            </Box>
        </Box>
    )
}
