import { createContext, useContext, useEffect, useState } from 'react'

const MyLocationContext = createContext<{
    position: GeolocationPosition | undefined
    error: string | undefined
}>({
    position: undefined,
    error: undefined,
})

export const useMyLocation = () => useContext(MyLocationContext)

export const MyLocationProvider = ({ children }) => {
    const [position, setPosition] = useState<GeolocationPosition>()
    const [error, setError] = useState<string>()

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            position => {
                setPosition(position)
            },
            () => {
                console.error('Failed to get location')
                setError('Failed to get location')
            },
        )
    }, [])

    return <MyLocationContext.Provider value={{ position, error }}>{children}</MyLocationContext.Provider>
}
