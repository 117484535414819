import { AdminPanelSettingsRounded, EmailRounded, LocalDiningRounded } from '@mui/icons-material'
import Box from '@mui/joy/Box'
import Divider from '@mui/joy/Divider'
import GlobalStyles from '@mui/joy/GlobalStyles'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Sheet from '@mui/joy/Sheet'
import Typography from '@mui/joy/Typography'
import { useUserData } from 'auth'
import logo from 'images/logo.svg'
import { Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { useMatch, useNavigate } from 'react-router'
import { closeSidebar } from '../utils'
import Profile from './profile'

function Toggler({
    defaultExpanded = false,
    renderToggle,
    children,
}: {
    defaultExpanded?: boolean
    children: ReactNode
    renderToggle: (params: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) => ReactNode
}) {
    const [open, setOpen] = useState(defaultExpanded)
    return (
        <>
            {renderToggle({ open, setOpen })}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': {
                        overflow: 'hidden',
                    },
                }}
            >
                {children}
            </Box>
        </>
    )
}

export default function Sidebar() {
    const { user } = useUserData()
    const navigate = useNavigate()

    const { currentPage } = useMatch('/:currentPage')?.params ?? {
        currentPage: '',
    }

    const navigateAndClose = (url: string) => () => {
        navigate(url)
        closeSidebar()
    }

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: {
                    xs: 'fixed',
                    md: 'sticky',
                },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 1299,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={theme => ({
                    ':root': {
                        '--Sidebar-width': '260px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '290px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 1208,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />

            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 4 }}>
                <img
                    src={logo}
                    alt="logo"
                    style={{ width: '100%', height: 'auto' }}
                />
            </Box>
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': theme => theme.vars.radius.sm,
                    }}
                >
                    <ListItem>
                        <ListItemButton
                            role="menuitem"
                            component="a"
                            onClick={navigateAndClose(`/restaurants`)}
                            selected={currentPage == 'restaurants'}
                        >
                            <LocalDiningRounded />
                            <ListItemContent>
                                <Typography level="title-sm">Nearby</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            role="menuitem"
                            component="a"
                            onClick={navigateAndClose(`/suggest`)}
                            selected={currentPage == 'suggest'}
                        >
                            <EmailRounded />
                            <ListItemContent>
                                <Typography level="title-sm">Suggest</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    {user.superUser && (
                        <ListItem>
                            <ListItemButton
                                role="menuitem"
                                component="a"
                                onClick={navigateAndClose(`/awaitingapproval`)}
                                selected={currentPage == 'awaitingapproval'}
                            >
                                <AdminPanelSettingsRounded />
                                <ListItemContent>
                                    <Typography level="title-sm">Awaiting Approval</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </Box>
            <Divider />
            <Profile />
        </Sheet>
    )
}
