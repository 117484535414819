import { API } from 'api'
import Loading from 'components/Loading'
import { useMaybeState } from 'helperFunctions/react'
import LogRocket from 'logrocket'
import { createContext, useContext, useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

export interface UserData {
    id: string
    firstName: string
    lastName: string
    email: string
    profileImage?: string
    superUser: boolean
}

export const UserDataContext = createContext<{
    user: UserData
    refreshUser: () => void
}>(null as any)
export const useUserData = () => useContext(UserDataContext)

export const AuthWrapper = ({ children }: { children }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const authRoute = location.pathname.includes('auth/')
    const [searchParams] = useSearchParams()
    const pageRedirect = searchParams.get('page_redirect')

    const [maybeUserData, setUserData] = useMaybeState<UserData>()
    const [notLoggedIn, setNotLoggedIn] = useState(false)

    const refreshUser = () => {
        const authToken = localStorage.getItem('token')

        if (!authToken) {
            setNotLoggedIn(true)
            return
        }

        API.get('/me', {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        })
            .then(({ data }: { data: UserData }) => {
                setUserData(data)
                console.log('init logrocket', import.meta.env.PROD)
                if (import.meta.env.PROD) {
                    LogRocket.init('halah/halah')
                    LogRocket.identify(data.email, {
                        name: `${data.firstName} ${data.lastName}`,
                        email: data.email,
                    })
                }
                if (authRoute) {
                    navigate(pageRedirect ?? '/')
                }
            })
            .catch(() => {
                setNotLoggedIn(true)
            })
    }

    useEffect(() => {
        const access_token = new URLSearchParams(window.location.hash.substring(1)).get('access_token')

        if (access_token) {
            localStorage.setItem('token', access_token)
        }
        refreshUser()
    }, [])

    if (authRoute) {
        return children
    }

    if (notLoggedIn && !authRoute) {
        const params =
            location.pathname !== '/'
                ? new URLSearchParams({
                      page_redirect: location.pathname + location.search,
                  })
                : ''

        return (
            <Navigate
                to={`/auth/login?${params.toString()}`}
                replace={true}
            />
        )
    }

    return maybeUserData
        .map(userData => (
            <UserDataContext.Provider
                value={{
                    user: {
                        userData,
                        ...(userData as any).properties,
                    },
                    refreshUser,
                }}
            >
                {children}
            </UserDataContext.Provider>
        ))
        .orSome(<Loading />)
}

export const logout = async () => {
    localStorage.removeItem('token')
    window.location.href = window.location.origin.replace(/\/$/, '')
}
