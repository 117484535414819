import { useUserData } from 'auth'
import axios from 'axios'

export const apiUrl = import.meta.env.VITE_APP_API_URL

export const API = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
})

export const useApi = () => {
    const { user } = useUserData()

    const token = localStorage.getItem('token')

    return axios.create({
        baseURL: apiUrl,
        //withCredentials: true,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
