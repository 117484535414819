import isMobilePhone from 'validator/es/lib/isMobilePhone'
import z from 'zod'

export const states = ['QLD', 'NSW', 'VIC', 'TAS', 'SA', 'WA', 'NT', 'ACT'] as const
export type State = (typeof states)[number]

export const mobileType = z.string().refine(val => isMobilePhone(val.replaceAll(' ', ''), 'en-AU'), {
    message: 'Invalid mobile number',
})
export const landlineType = z
    .string()
    .regex(/^\(?(0[2378])\)?[\s-]?(\d{4})[\s-]?(\d{4})$/, { message: 'Invalid landline number' })

export const mobileOrPhoneType = z.union([mobileType, landlineType], {
    errorMap: error => ({ message: 'Invalid mobile or phone number' }),
})

export const AddressSchema = z.object({
    unitNumber: z.string().optional(),
    streetNumber: z.string(),
    streetName: z.string(),
    suburb: z.string(),
    postcode: z.string().length(4),
    state: z.enum(states),
})

export const formatAddress = (address: AddressSchema, type: 'full' | 'short' = 'short') => {
    const short = `${address.unitNumber && address.unitNumber.length > 0 ? address.unitNumber + '/' : ''}${address.streetNumber} ${address.streetName}`
    if (type === 'short') {
        return short
    } else {
        return `${short}, ${address.suburb} ${address.state} ${address.postcode}`
    }
}

export type AddressSchema = z.infer<typeof AddressSchema>
