import { HalalStatuses } from '@halah/core/src/schemas/restaurant'
import { Box, Checkbox, Stack, Typography } from '@mui/joy'
import { CuisineInput } from 'addRestaurant'
import { assoc, prop } from 'ramda'

export default ({ filters, setFilters, setScreen }) => {
    return (
        <Box
            sx={{
                mt: 6,
            }}
        >
            <Typography sx={{ mb: 1 }}>Halal Status</Typography>
            <Stack
                direction="row"
                gap={2}
            >
                {HalalStatuses.map(status => (
                    <Checkbox
                        label={status}
                        checked={filters.halalStatus?.includes(status)}
                        onChange={e =>
                            setFilters({
                                ...filters,
                                halalStatus: e.target.checked
                                    ? [...(filters.halalStatus ?? []), status]
                                    : (filters.halalStatus ?? []).filter(x => x !== status),
                            })
                        }
                    />
                ))}
            </Stack>

            <Typography sx={{ mt: 2 }}>Cuisines</Typography>
            <CuisineInput
                value={filters.cuisines?.map(assoc('id')) ?? []}
                onChange={value => setFilters({ ...filters, cuisines: value.map(prop('id')) })}
            />
        </Box>
    )
}
